var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-board"},[_c('DialogMask'),_c('div',{staticClass:"board-list",style:({ transform: ("translateX(" + _vm.touchOffset + "px)") })},[_vm._l((_vm.dayLogsNought),function(log,i){return _c('Card',{key:("log_nought_" + i),staticClass:"elevation-1",attrs:{"name":log.name,"theme":"nought","icon":"empty-set","to":("/logs/" + (log.refId))}})}),_vm._l((_vm.dayLogsComplete),function(log,i){return _c('Card',{key:("log_complete_" + i),staticClass:"elevation-1",attrs:{"name":log.name,"theme":!log.positive
					? 'bad'
					: log.completion.value == 1
					? 'great'
					: 'good',"icon":!log.positive
					? 'skull'
					: log.completion.value == 1
					? 'star'
					: 'check',"to":("/logs/" + (log.refId))}})})],2),(_vm.loading)?_c('div',{staticClass:"board"},[_vm._v(" Loading... ")]):_c('div',{staticClass:"board",style:({ transform: ("translateX(" + _vm.touchOffset + "px)") })},[_c('div',{directives:[{name:"touch",rawName:"v-touch:start",value:(_vm.swipeStart),expression:"swipeStart",arg:"start"},{name:"touch",rawName:"v-touch:end",value:(_vm.swipeStop),expression:"swipeStop",arg:"end"},{name:"touch",rawName:"v-touch:moving",value:(_vm.swipeMoving),expression:"swipeMoving",arg:"moving"}],staticClass:"board-day"},[_c('span',[_vm._v(_vm._s(_vm.daysFromNow))]),_c('span',[_vm._v(_vm._s(_vm.day))])]),_c('div',{staticClass:"board-schedule"},[(_vm.daySchedule.length == 0)?_c('div',{staticClass:"schedule"},[_c('Card',{staticClass:"active light",attrs:{"name":"Empty","theme":"info","icon":"info-circle"}})],1):_vm._e(),_c('div',{staticClass:"schedule"},[_c('div',{staticClass:"cards"},_vm._l((_vm.daySchedule),function(entity,i){return _c('div',{key:i,staticClass:"card-slot"},[_c('Card',{key:("schedule_" + i),staticClass:"card-v active xs",attrs:{"name":entity.name,"state":entity.type,"fnClick":function () { return _vm.logFromHand(entity); }}})],1)}),0)])])]),_c('AddLogMenu',{attrs:{"pinned":_vm.pinned},on:{"select":_vm.addLogClick,"togglePinned":function (id) { return _vm.togglePinned(id); }}}),_c('PlanMenu'),_c('PlanContextMenu',{attrs:{"plan":_vm.selectedPlan},on:{"createLog":_vm.createLog}}),_c('Plan'),_c('div',{staticClass:"action-bar-container"},[_c('div',{staticClass:"action-bar-row"},[_c('div',[_c('div',{staticClass:"action-bar"},[_c('button',{on:{"click":function($event){$event.preventDefault();return (function () { return _vm.decrDayOffset(); })($event)}}},[_c('i',{staticClass:"fad fa-fw fa-angle-left"})]),_c('button',{on:{"click":function($event){$event.preventDefault();return (function () { return _vm.incrDayOffset(); })($event)}}},[_c('i',{staticClass:"fad fa-fw fa-angle-right"})]),_c('a',{class:[
							'btn',
							{ active: _vm.dialog.stack[0] == _vm.dialogKeys.PLANS } ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$router.push('/plans'); })($event)}}},[_c('i',{staticClass:"fad fa-fw fa-calendar"}),_c('label',[_vm._v("Plan")])])])]),_c('a',{staticClass:"btn btn-action active",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.setDialog(_vm.dialogKeys.LOGS_ADD); })($event)}}},[_c('i',{staticClass:"fad fa-fw fa-file-plus"}),_c('label',[_vm._v("Log Task")])])])]),_c('ActionDesigner'),_c('SequenceDesigner'),_c('SideMenu',{attrs:{"dialogKey":"log-from-hand"}},[_c('div',{staticClass:"menu-heading"},[_c('i',{staticClass:"fad fa-fw fa-file-plus"}),_vm._v("Log a Task ")]),_vm._l((_vm.filteredEntities),function(entity,i){return _c('Card',{key:("from-hand_" + i),staticClass:"text light boxed",attrs:{"name":entity.name,"icon":_vm.getLogIcon(entity),"theme":entity.positive ? 'good' : 'bad',"fnClick":function () { return _vm.createLog(entity.refId); }}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }