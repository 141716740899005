<template>
	<transition name="dialog-anim">
		<div v-if="active" class="side-dialog menu active" @click.stop>
			<div v-if="plan">
				<div class="menu-heading">
					<i class="fal fa-fw fa-calendar"></i>{{ plan.name }}
				</div>
				<div class="menu-subheading">
					Log a Task
				</div>
				<div class="menu-item" v-for="(task, j) in plan.tasks" :key="j">
					<i :class="['menu-icon fa-fw fal', getIcon(task.type)]"></i>
					<a
						class="menu-title"
						href="#"
						@click.prevent="() => createLog(task.refId)"
						>{{ task.name }}</a
					>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from "vuex";
import { PLAN_CONTEXT } from "../../data/dialog-keys";

export default {
	name: "PlanContextMenu",
	props: {
		plan: Object,
	},
	computed: {
		...mapGetters({
			dialog: "dialog",
			plans: "plans/data",
		}),
		active() {
			return this.dialog.current == PLAN_CONTEXT;
		},
	},
	methods: {
		close() {
			this.$emit("close");
		},
		createLog(taskId) {
			this.$emit("createLog", taskId);
		},
		getIcon(type) {
			switch (type) {
				case "action":
					return "fa-credit-card-blank";
				case "sequence":
					return "fa-bars";
				case "series":
					return "fa-line-columns";
			}

			return "fa-dot";
		},
	},
};
</script>
