<template>
	<div class="page page-board">
		<DialogMask />

		<div
			class="board-list"
			:style="{ transform: `translateX(${touchOffset}px)` }"
		>
			<Card
				v-for="(log, i) in dayLogsNought"
				:key="`log_nought_${i}`"
				class="elevation-1"
				:name="log.name"
				theme="nought"
				icon="empty-set"
				:to="`/logs/${log.refId}`"
			/>

			<Card
				v-for="(log, i) in dayLogsComplete"
				:key="`log_complete_${i}`"
				class="elevation-1"
				:name="log.name"
				:theme="
					!log.positive
						? 'bad'
						: log.completion.value == 1
						? 'great'
						: 'good'
				"
				:icon="
					!log.positive
						? 'skull'
						: log.completion.value == 1
						? 'star'
						: 'check'
				"
				:to="`/logs/${log.refId}`"
			/>
		</div>

		<div class="board" v-if="loading">
			Loading...
		</div>
		<div
			v-else
			class="board"
			:style="{ transform: `translateX(${touchOffset}px)` }"
		>
			<div
				class="board-day"
				v-touch:start="swipeStart"
				v-touch:end="swipeStop"
				v-touch:moving="swipeMoving"
			>
				<span>{{ daysFromNow }}</span>
				<span>{{ day }}</span>
			</div>

			<div class="board-schedule">
				<div class="schedule" v-if="daySchedule.length == 0">
					<Card
						name="Empty"
						class="active light"
						theme="info"
						icon="info-circle"
					/>
				</div>
				<div class="schedule">
					<div class="cards">
						<div
							class="card-slot"
							v-for="(entity, i) in daySchedule"
							:key="i"
						>
							<Card
								:key="`schedule_${i}`"
								class="card-v active xs"
								:name="entity.name"
								:state="entity.type"
								:fnClick="() => logFromHand(entity)"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<AddLogMenu
			@select="addLogClick"
			:pinned="pinned"
			@togglePinned="(id) => togglePinned(id)"
		/>

		<PlanMenu />
		<PlanContextMenu :plan="selectedPlan" @createLog="createLog" />

		<Plan />

		<div class="action-bar-container">
			<div class="action-bar-row">
				<div>
					<div class="action-bar">
						<button @click.prevent="() => decrDayOffset()">
							<i class="fad fa-fw fa-angle-left"></i>
						</button>
						<button @click.prevent="() => incrDayOffset()">
							<i class="fad fa-fw fa-angle-right"></i>
						</button>

						<a
							href="#"
							:class="[
								'btn',
								{ active: dialog.stack[0] == dialogKeys.PLANS },
							]"
							@click.prevent="() => $router.push('/plans')"
						>
							<i class="fad fa-fw fa-calendar"></i>
							<label>Plan</label>
						</a>
					</div>
				</div>

				<a
					href="#"
					class="btn btn-action active"
					@click.prevent="() => setDialog(dialogKeys.LOGS_ADD)"
				>
					<i class="fad fa-fw fa-file-plus"></i>
					<label>Log Task</label>
				</a>
			</div>
		</div>
		<ActionDesigner />
		<SequenceDesigner />

		<SideMenu dialogKey="log-from-hand">
			<div class="menu-heading">
				<i class="fad fa-fw fa-file-plus"></i>Log a Task
			</div>
			<Card
				v-for="(entity,i) in filteredEntities"
				:key="`from-hand_${i}`"
				:name="entity.name"

				class="text light boxed"
				:icon="getLogIcon(entity)"
				:theme="entity.positive ? 'good' : 'bad'"
				:fnClick="() => createLog(entity.refId)"
				/>
		</SideMenu>
	</div>
</template>

<script>
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
dayjs.extend(durationPlugin);

import { AddLogMenu } from "../components/menus/index";

import { mapGetters, mapActions } from "vuex";
// import SideMenu from "../components/menus/SideMenu";

import ActionDesigner from "../components/designers/ActionDesigner";
import SequenceDesigner from "../components/designers/SequenceDesigner";

import PlanMenu from "../components/menus/PlanMenu";
import PlanContextMenu from "../components/menus/PlanContextMenu";
import Plan from "../components/dialogs/Plan";
import DialogMask from "../components/DialogMask";
import Card from "../components/Card";

import SideMenu from "../components/menus/SideMenu";

import { mod } from "../helpers/math";
import { isnull } from "../helpers/objects";
import { isScheduled } from "../helpers/calendar";

import {
	LOGS_ADD,
	PLANS,
	PLAN_CONTEXT,
	// SEQUENCE,
	ACTION,
} from "../data/dialog-keys";

export default {
	name: "Board",
	components: {
		ActionDesigner,
		SequenceDesigner,
		PlanMenu,
		PlanContextMenu,
		AddLogMenu,
		Plan,
		DialogMask,
		Card,
		SideMenu
	},
	data: () => ({
		touchOffset: 0,
		touchStart: 0,
		loading: false,
		menuActive: false,
		logs_nought: [],
		logs_complete: [],
		showListMenu: false,
		pinned: [],
		selectedPlan: null,
		filteredEntities: []
	}),
	computed: {
		...mapGetters({
			dayOffset: "dayOffset",
			dialog: "dialog",
			user: "user/data",
			logs: "logs/data",
			plans: "plans/data",
			tasks: "tasks/data",
			habits: "habits/data",
			schedules: "schedules",
		}),
		dialogKeys: () => ({ LOGS_ADD, PLANS, ACTION }),
		menuOpen() {
			return (
				this.showPinMenu ||
				this.showCreateTaskMenu ||
				this.showListMenu ||
				this.showAddMenu
			);
		},
		dayStart() {
			const now = Date.now();
			const day = dayjs(now).add(this.dayOffset, "day");
			const today = day.format("YYYY-MM-DD");
			const start = dayjs(today + "T00:00:00").unix() * 1000;
			return start;
		},
		dayEnd() {
			const now = Date.now();
			const day = dayjs(now).add(this.dayOffset, "day");
			const today = day.format("YYYY-MM-DD");
			const end = dayjs(today + "T23:59:59").unix() * 1000;
			return end;
		},
		day() {
			const now = Date.now();
			const day = dayjs(now).add(this.dayOffset, "day");
			return day.format("ddd, DD MMM YYYY");
		},
		daySchedule() {
			const unix = this.dayStart / 1000;
			const day = dayjs.unix(unix);
			console.log(day);

			const date = day.date();
			const weekday = mod(day.day() - 1, 7);
			const month = day.month() + 1;
			const year = day.year();

			const items = this.schedules.list
				.filter((x) =>
					isScheduled({ unix, date, weekday, month, year }, x)
				)
				.map((x) => ({
					name: x.name,
					type: x.type,
					refId: x.refId
				}));

			return items;
		},
		dayLogs() {
			return (this.logs.list || []).filter(
				(l) => l.date >= this.dayStart && l.date <= this.dayEnd
			);
		},
		dayLogsNought() {
			return this.dayLogs.filter(
				(x) =>
					!x.completion ||
					x.completion.value == null ||
					x.completion.value === 0
			);
		},
		daysFromNow() {
			if (this.dayOffset < -1)
				return `${-this.dayOffset} days ago`;

			if (this.dayOffset > 1)
				return `${this.dayOffset} days from now`;

			switch (this.dayOffset) {
				case 0:
					return "Today";
				case -1:
					return "Yesterday";
				case 1:
					return "Tomorrow";
			}
			return "";
		},
		dayLogsComplete() {
			return this.dayLogs.filter(
				(x) => x.completion && x.completion.value > 0
			);
		},
	},
	created() {
		this.load();
	},
	methods: {
		...mapActions({
			loadAll: "loadAll",
			pushDialog: "pushDialog",
			popDialog: "popDialog",
			setDialog: "setDialog",
			setSequenceContext: "tasks/setSequenceContext",
			createLogAction: "logs/create",
			updateUserPinned: "user/updatePinnedDebounce",
			openSingleActionDesigner: "tasks/openSingleActionDesigner",
			incrDayOffset: "incrDayOffset",
			decrDayOffset: "decrDayOffset",
		}),
		async load() {
			this.loading = true;
			await this.loadAll();

			if (!this.user || !this.user.current) return;

			this.pinned = [...(this.user.current.pinned || [])];
			this.loading = false;
			this.logs_nought = this.logs.list.filter(
				(x) =>
					!x.completion ||
					x.completion.value == null ||
					x.completion.value === 0
			);
			this.logs_complete = this.logs.list.filter(
				(x) => x.completion && x.completion.value > 0
			);
		},
		async logCreated(e) {
			if (e.data.refId) this.$router.push(`/logs/${e.data.refId}`);
		},
		swipeStart(e) {
			if (this.menuOpen) return;

			if (!e || !e.changedTouches || !e.changedTouches.length) return;

			this.touchStart = e.changedTouches[0].clientX;
		},
		swipeMoving(e) {
			if (this.menuOpen) return;

			if (!e || !e.changedTouches || !e.changedTouches.length) return;

			this.touchOffset = e.changedTouches[0].clientX - this.touchStart;
		},
		swipeStop() {
			if (this.menuOpen) {
				this.touchOffset = 0;
				return;
			}

			if (this.touchOffset > 20) {
				this.decrDayOffset();
			} else if (this.touchOffset < -20) {
				this.incrDayOffset();
			}

			this.touchStart = 0;
			this.touchOffset = 0;
		},
		wait(fn, millis) {
			setTimeout(fn, millis);
		},

		getIcon(type) {
			switch (type) {
				case "action":
					return "fa-credit-card-blank";
				case "sequence":
					return "fa-bars";
				case "series":
					return "fa-line-columns";
			}

			return "fa-dot";
		},
		togglePinned(taskId) {
			const idx = this.pinned.findIndex((id) => id == taskId);
			if (idx >= 0) this.pinned.splice(idx, 1);
			else this.pinned.push(taskId);

			this.updateUserPinned(this.pinned);
		},
		isPinned(taskId) {
			return this.pinned.includes(taskId);
		},
		addLogClick(task) {
			this.createLog(task.refId);
		},
		async createLog(taskRefId) {
			this.loading = true;

			const res = await this.createLogAction({
				taskRefId,
				date: this.dayStart,
			});

			if (res && res.data) this.logCreated(res);
			this.loading = false;
		},
		// openTask(task) {
		// 	if (!task) return;
		// 	const { _id, type } = task;
		// 	switch (type) {
		// 		case "action":
		// 			this.openSingleActionDesigner({
		// 				id: _id,
		// 				type,
		// 				isTask: true,
		// 			});
		// 			break;

		// 		case "sequence":
		// 			this.setSequenceContext(task);
		// 			this.setDialog(SEQUENCE);
		// 			// this.$router.push("/sequences/" + (_id || "create"));
		// 			break;
		// 	}
		// },
		setPlanContext(plan) {
			this.selectedPlan = plan;
			this.setDialog(PLAN_CONTEXT);
		},
	
		logFromHand(entity) {
			switch (entity.type) {
				case "action":
				case "sequence":
					this.createLog(entity.refId);
					break;

				case "tag":
				case "habit":
					this.filterEntities(entity.type, entity.refId);
					this.pushDialog("log-from-hand");
					break;
			}
		},
		filterEntities(type, refId)
		{
			this.filteredEntities = [];
			switch (type)
			{
				case "tag":
					this.filteredEntities = this.tasks.list.filter(task => {
						return (task.tags || []).findIndex(x => x == refId) >= 0;
					});
					return;

				case "habit":
					this.filteredEntities = this.tasks.list.filter(task => {
						return (task.habits || []).findIndex(x => x.refId == refId) >= 0;
					});
					return;
			}
			
		},
		
		getLogIcon(entity) {
			var positive = isnull(entity.positive, true);
			if (positive) return "trophy";
			else return "skull";
		},
	},
};
</script>
