<template>
	<div>
		<transition name="dialog-anim">
			<div v-if="active" class="dialog active" @click.stop>
				<template v-if="current.open">
					<div class="dialog-heading">{{ heading }}</div>
					<div class="dialog-body">
						<TextInput label="Name" v-model="params.name" />
					</div>
					<div class="dialog-title">
						<h4>Schedule</h4>
						<a
							@click.prevent="() => pushDialog('plan-schedule')"
							href="#"
							><i class="fal fa-fw fa-list"></i
						></a>
					</div>
					<Calendar />
					<div class="dialog-actions">
						<a class="btn text" href="#">
							<i class="fal fa-fw fa-save"></i>
						</a>
					</div>
				</template>
			</div>
		</transition>

		<SideMenu
			:dialogKey="dialogKeys.PLAN_SCHEDULE"
			:open="scheduleMenuActive"
			@close="() => (scheduleMenuOpen = false)"
		>
			<div v-if="scheduleMenuActive">
				<div class="menu-heading">
					{{ current.data.name }} Schedules
				</div>
				<div class="menu-item" v-for="(schedule,i) in current.data.schedules" :key="i">
					<a class="menu-title" @click.prevent="openSchedule" href="#">{{schedule.name}}</a>
					<a class="menu-action" @click.prevent="assignSchedule" href="#"><i class="fal fa-fw fa-check"></i></a>
				</div>
			</div>
		</SideMenu>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TextInput } from "../inputs/index";
import SideMenu from "../menus/SideMenu";
import { PLAN, PLAN_SCHEDULE } from "../../data/dialog-keys";
import Calendar from "../Calendar";

export default {
	components: { TextInput, SideMenu, Calendar },
	computed: {
		...mapGetters({
			dialog: "dialog",
			current: "plans/current",
		}),
		active() {
			return this.dialog.current == PLAN && this.current.open;
		},
		dialogKeys: () => ({ PLAN_SCHEDULE }),
		scheduleMenuActive() {
			return this.dialog.current == PLAN_SCHEDULE;
		},
		heading() {
			if (this.current.open && this.current.data._id) {
				return `Edit '${this.current.data.name}'`;
			}
			return "Create New Plan";
		},
	},
	data: () => ({
		params: {
			name: "",
			assigned: null
		},
		scheduleMenuOpen: false,
	}),
	watch: {
		"current.open"(open) {
			if (open) {
				this.copyData(this.current.data);
			}
		},
	},
	methods: {
		...mapActions({
			pushDialog: "pushDialog",
			closeCurrent: "plans/close",
		}),
		copyData(data) {
			this.params.name = data.name;
		},
		close() {
			this.closeCurrent();
		},
	},
};
</script>
