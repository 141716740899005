<template>
	<SideMenu :dialogKey="dialogKeys.LOGS_ADD">
		<div v-if="loading">
			Loading...
		</div>
		
		<div class="menu-heading">
			<i class="fad fa-fw fa-file-plus"></i>Log a Task
		</div>
		<div class="menu-item" v-if="!tasks.list || !tasks.list.length">
			<div class="menu-title">No Tasks</div>
		</div>

		<Card
			v-for="(task,i) in tasks.list"
			:key="`log-task_${i}`"

			class="text light boxed"
			:name="task.name"
			:icon="getPositiveIcon(task.positive)"
			:theme="task.positive ? 'good' : 'bad'"
			:fnClick="() => createLog(task)"
			/>

	</SideMenu>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SideMenu from "./SideMenu";
import { LOGS_ADD } from "../../data/dialog-keys";
import Card from "../Card";

export default {
	components: {
		SideMenu, Card
	},
	computed: {
		...mapGetters({
			menu: "tasks/addMenu",
			tasks: "tasks/data",
			focused: "tasks/focused",
		}),
		dialogKeys: () => ({ LOGS_ADD }),
	},
	data: () => ({
		loading: false,
	}),
	methods: {
		...mapActions({
			focus: "tasks/focus",
			createLogAction: "logs/create",
			openSingleActionDesigner: "tasks/openSingleActionDesigner",
		}),
		...mapMutations({
			setMenuActiveAction: "tasks/addMenuSetActive",
		}),
		getIcon(type) {
			switch (type) {
				case "action":
					return "fa-credit-card-blank";
				case "sequence":
					return "fa-bars";
				case "series":
					return "fa-line-columns";
			}

			return "fa-dot";
		},
		async createLog(task) {
			this.$emit("select", task);
			this.close();
		},
		close() {
			this.$emit("close");
		},
		getPositiveIcon(positive) {
			if (positive == null) positive = true;

			if (positive) return "trophy";
			else return "skull";
		},
	},
};
</script>
