<template>
	<transition name="dialog-anim">
		<div v-if="active" class="side-dialog menu active" @click.stop>
			<div class="menu-heading">
				<i class="fal fa-fw fa-calendar"></i>Your Plans
			</div>
			<div class="menu-item">
				<a
					class="menu-title"
					@click.prevent="() => openPlan(null)"
					href="#"
				>Add New Plan</a>
			</div>
			<div class="menu-divider"></div>
			<div class="menu-item" v-for="(plan, i) in plans.list" :key="i">
				<a
					class="menu-title"
					@click.prevent="() => openPlan(plan._id)"
					v-text="plan.name"
					href="#"
				></a>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	name: "PlanMenu",
	computed: {
		...mapGetters({
			dialog: "dialog",
			plans: "plans/data",
		}),
		active() {
			return this.dialog.current == "plans";
		}
	},
	methods: {
		...mapActions({
			openPlan: "plans/open",
		}),
		close() {
			this.$emit("close");
		},
	},
};
</script>
